import React from "react";
import { graphql } from "gatsby";
import { Pagination } from "../components/pagination";
import { ParentPage } from "../components/parentPage";
import { FileLink } from "../components/link";

const ParentNewsletter = ({
  data,
  pageContext,
}: {
  pageContext: {
    limit: number;
    skip: number;
    prev?: string;
    next?: string;
  };
  data: {
    allSanityParentNewsletter: {
      edges: [];
    };
  };
}) => (
  <ParentPage>
    <div className="max-w-4xl">
      <h1 className="mb-6 mt-4">Newsletters</h1>
      {data.allSanityParentNewsletter.edges.map((edge, i) => (
        <FileLink
          key={i}
          file={edge.node._rawNewsletter}
          title={edge.node.title}
          subtitle={new Date(edge.node.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        />
      ))}
      <Pagination {...pageContext} />
    </div>
  </ParentPage>
);

export const query = graphql`
  query sanityParentNewsletterQuery($skip: Int!, $limit: Int!) {
    allSanityParentNewsletter(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          _rawNewsletter(resolveReferences: { maxDepth: 10 })
          title
          date
        }
      }
    }
  }
`;

export default ParentNewsletter;
